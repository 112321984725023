import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {Link} from 'gatsby'
import CTA from './CTA'
import { phoneNumber, email } from '../content/content.json'
import PhoneIcon from './icons/PhoneIcon';
import EmailIcon from './icons/EmailIcon';


const Footer = props => {
    let location = typeof window !== 'undefined' ? window.location.pathname.replace('/', '') : '';
    return(
        <footer className="footer">
            <div className="upper">
                <StaticImage src="../../static/images/logo.png" alt="logo" className="header-logo" placeholder="tracedSVG" objectFit="contain" />
                {location !== "contact" && <CTA.form />}
            </div>
            <div className="lower">
                <div className="footer-menu">
                    <div className="title">Pages</div>
                    <ul>
                        <li>
                            <Link to="/">Accueil</Link>
                        </li>
                        <li>
                            <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                            <Link to="/#nos-services">Nos services</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-menu">
                    <div className="title">Contact</div>
                    <ul>
                        <li>
                            <PhoneIcon className="footer-icon"/>
                            <a href={`tel:${phoneNumber.split('').join('')}`}>{phoneNumber}</a>
                        </li>
                        <li>
                            <EmailIcon className="footer-icon"/>
                            <a href={`mailto:${email}`}>{email}</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-menu">
                    <div className="title">Nos horaires</div>
                    <ul className="hours">
                        <li>
                            <div>Lundi à Vendredi:&nbsp;</div>
                            <div className="hour">08h00 <span>à</span> 18h00</div>
                        </li>
                        <li>
                            <div>Samedi:&nbsp;</div>
                            <div className="hour">08h00 <span>à</span> 12h30</div>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
