import React, { useCallback} from 'react';
import SearchIcon from '../components/SearchIcon'
import {navigate} from 'gatsby'
import {phoneNumber} from '../content/content.json'

const CTA = props => {
    return <div className="cta">
        <div className="cta-text">
            Demandez un devis au 
            <span>
                &nbsp;<a href={`tel:${phoneNumber.split('').join('')}`}>{phoneNumber}</a>&nbsp;
            </span>
            ou entrez votre code postal ici:
        </div>
        <CTA.form/>
    </div>
}

const Form = () => {
    const [codePostal, setCodePostal] = React.useState('');

    const CTARedirect = useCallback(() => {
        navigate(`/contact?codepostal=${encodeURIComponent(codePostal)}`)
    })

    return (
        <div className="cta-input-div">
            <input
                type="text"
                name="codePostal"
                value={codePostal}
                placeholder="Code postal:"
                onChange={(e) => setCodePostal(e.target.value)}
                onKeyUp={e => {
                    if (e.key === 'Enter') CTARedirect()
                }}
            />
            <div className="cta-search-box" onClick={()=>{CTARedirect()}}>
                <SearchIcon />
            </div>
        </div>
    )
}

CTA.form = Form;


export default CTA;