import React, {useState} from 'react';
import '../scss/mains.scss'
import { StaticImage } from "gatsby-plugin-image"
import {Link} from "gatsby"


const Header = () =>{
    const [expand, setExpand] = useState(false);
    const toggle = () => setExpand(!expand);
    return (
        <div>
            <div className="header">
                <div className="header-content">
                    <div className={`hamburger-button ${expand ? 'expand' : ''}`} onClick={()=>toggle()}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <Link to="/">
                    <StaticImage alt="" src="../../static/images/logo.png" alt="logo" className="header-logo" placeholder="tracedSVG" loading="eager" />
                </Link>
                <div className="ghost-button"></div>
                    <div className="routes">
                        <Link to="/">Accueil</Link>
                        <Link to="/faq">FAQ</Link>
                        <Link to="/#nos-services">Nos services</Link>
                    </div>
                    <Link to="/contact" className="contact-button">
                        Contact
                    </Link>
                </div>
            </div>
            <div className={`header-mobile-menu ${expand ? "expand" : ""}`}>
                <div className="routes-mobile">
                    <Link to="/">Accueil</Link>
                    <Link to="/faq">FAQ</Link>
                    <Link to="/#nos-services">Nos services</Link>
                    <Link to="/contact">Contact</Link>
                </div>
            </div>
        </div>
  )
}

export default Header;
